'use strict';

var HangmanLetter = React.createClass({
    childContextTypes: {
        letter: React.PropTypes.object,
    },
    getDefaultProps: function () {
        return {
            letter: "",
            answer: "",
            image_id: ""
        }
    },
    propTypes: {
        letter: React.PropTypes.string
    },
    getInitialState: function() {
        return this.props.object
    },
    onFocus: function(e) {

        return this.props.onFocus(e, this.state);
    },
    onBlur: function(e) {
        return this.props.onBlur(e, this.state);
    },
    onChange: function(e) {
        return this.props.onChange(e, this.state);
    },
    componentWillMount: function() {
        this.setState(this.props.object);
    },
    render:function() {
        var className = "";

        var input_disabled = this.state.hold;

        if(this.props.object.answer){
            className += " correct";
        }
        if(this.props.object.hold){
            className += " hold";
        }
        if(this.props.object.letter == " "){
            className += " spice";
        }
        var value = this.props.object.answer || "";

            return (<li className={className} data-letter={this.state.string_num}><input disabled={input_disabled} onBlur={this.onBlur} onChange={this.onChange} onFocus={this.onFocus} tabIndex = {this.state.tab_index}  type="text" maxLength="2" value={value}/></li>)
    }

});

var HangmanGame = React.createClass({
    getInitialState: function() {
        return {
            city: Math.random() > 0.5 ? "singapore" : "tokyo",
            answer: "",
            letters: [],
            correct_chars: "",
            mistakes: 10,
            prev_index :0
        };
    },
    onKeyUpLetter: function(e, cell){
        if(this.state.finish) return;
        var key = e.keyCode || e.which;
        var letter = String.fromCharCode(e.charCode || e.keyCode).toLowerCase();
        var answer = this.state.answer.split(letter).join("")+letter;
        //this.setState({answer: answer});
//console.log(e.keyCode, e.which,this.state);
        if(key == 9){
        }
    },
    onFocusLetter: function(e, cell){
    },
    onBlurLetter: function(e, cell){
    },
    onChangeLetter: function(e, cell){
        if(this.state.finish) return;
        var letter = e.target.value.toLowerCase();
        for(var i = 0; i< letter.length;i++){
            this.state.answer = this.state.answer.split(letter[i]).join("")+letter[i];
        }

        this.setState({letters: this.fetchLetters(), answer: this.state.answer});

    },
    fetchLetters: function(){
        var letters = [];
        var correct_count = 0;
        var correct_chars="";
        var tab_index = 0;
        for(var i = 0; i < this.state.city.length;i ++){
            var letter = {letter: this.state.city[i]};
            correct_chars=correct_chars.split(letter.letter).join("") + letter.letter;
            if(letter == " " || this.state.answer.indexOf(letter.letter)>-1){
                letter.answer = letter.letter;
                letter.tab_index = -1;
                console.log("letter.tab_index: ", letter.tab_index);
            }else{
                letter.tab_index = tab_index;
                tab_index++;

            }

            letters.push(letter);

        }

        for(var i = 0; i < this.state.answer.length;i ++) {
            if(this.state.city.indexOf(this.state.answer[i])>-1) {
                correct_count++;
            }
        }
        this.state.correct_chars = correct_chars;

        var mistakes = Math.max(this.state.mistakes, correct_chars.length-1);

        for(var i = 0; i < mistakes;i ++) {
            $("path#man_" + i).hide();
            $("g#man_" + i).hide();
            $("rect#man_" + i).hide();
        }
        var wrongs = this.state.answer.length - correct_count;

        for(var i = mistakes + 2; i > mistakes - wrongs - 1;i --) {
            $("path#man_" + i).show();
            $("g#man_" + i).show();
            $("rect#man_" + i).show();
        }
        if(wrongs == this.state.mistakes || correct_count ==  correct_chars.length){
            this.skipGame(false);
        }

        return letters;

    },
    componentDidMount: function() {
        this.fetchLetters()
    },
    componentWillMount: function() {
        var geocoder;


        function successFunction(position) {
            var lat = position.coords.latitude;
            var lng = position.coords.longitude;
            codeLatLng(lat, lng)
        }
        function errorFunction(){
            //alert("Geocoder failed");
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
        }
        var _this = this;
        function codeLatLng(lat, lng) {
            var latlng = new google.maps.LatLng(lat, lng);
            geocoder.geocode({'latLng': latlng, language: "ru"}, function(results, status) {
                //console.log(results, status);
                if (status == google.maps.GeocoderStatus.OK) {
                    if (results[1]) {
                        var city = "";
                        for (var i=0; i<results[0].address_components.length; i++) {
                            for (var b=0;b<results[0].address_components[i].types.length;b++) {
                                if (results[0].address_components[i].types[b] == "administrative_area_level_1") {
                                    city = results[0].address_components[i];
                                    break;
                                }
                            }
                        }
                        _this.setState({city: city.long_name.toLowerCase().split("город ").join("")});
                        _this.setState({letters: _this.fetchLetters()});
                    } else {
                        alert("No results found");
                    }
                } else {
                    alert("Geocoder failed due to: " + status);
                }
            });
        }

        geocoder = new google.maps.Geocoder();

        this.setState({letters: this.fetchLetters()});
        if(this.props.solved){
            this.skipGame();
        }

    },
    showLetter: function(n){
        var _this = this;
        setTimeout(function(){
            _this.state.letters[n].answer = _this.state.letters[n].letter;
            _this.setState({letters: _this.state.letters});
        }, n * 300);
    },
    skipGame: function(animate){
            //for(var i = 0; i < this.state.letters.length; i++) {
            //        this.showLetter(i);
            //}
        this.setState({finish: true});
    },
    render:function() {
            if (this.props.solved && !this.state.finish) {

                this.state.finish = true;
                for(var i = 0; i < this.state.letters.length; i++) {
                    this.showLetter(i);
                }
            }

            var letters = [];
            var answers = 0;

            for (var i = 0; i < this.state.letters.length; i++) {
                answers += this.state.letters[i].answer ? 1 : 0;
                letters.push(<HangmanLetter key={i} object={this.state.letters[i]} onBlur={this.onBlurLetter}
                                            onChange={this.onChangeLetter} onFocus={this.onFocusLetter}/>)
            }
            var className = "";

            if (answers == this.state.letters.length) {
                className = "correct ";
            }
            return (
                <div>
                    <svg version="1.1" id="man_svg" x="0px" y="0px" width="590px" height="421px"
                         viewBox="-105 0 590 421" enable-background="new -105 0 590 421">
                        <path id="man_1" name="leg_r" fill="none" stroke="#000000" strokeWidth="6"
                              d="M394.646,265c39.466,55.721,19.084,29.588,60.746,78.709"/>
                        <path id="man_2" name="leg_l" fill="none" stroke="#000000" strokeWidth="6"
                              d="M397.344,264c-43.607,52.542-23.641,26.091-60.305,79.047"/>
                        <path id="man_3" name="hand_r" fill="none" stroke="#000000" strokeWidth="6"
                              d="M393.039,174.459 c62.038,28.522,31.32,16.082,91.961,37.791"/>
                        <path id="man_4" name="hand_l" fill="none" stroke="#000000" strokeWidth="6"
                              d="M390,174.459c-62.037,28.522-31.32,16.082-91.961,37.791"/>
                        <path id="man_5" name="body" fill="none" stroke="#000000" strokeWidth="6"
                              d="M392.039,136c0,103.264,1.484,84.635,1.484,132.391"/>
                        <path id="man_6" fill="none" stroke="#000000" strokeWidth="3"
                              d="M377.918,111.812c12.885,0.697,25.768,1.393,38.652,2.09"/>
                        <g id="man_7">
                            <path id="Path-25" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M414.957,78.129c-2.473,11.986-2.293,6.421-1.395,16.645"/>
                            <path id="Path-26" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M407.898,85.93c11.906,2.36,7.208,3.399,14.422,0.566"/>
                            <path id="Path-27" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M409.25,78.52l7.145,12.637"/>
                            <path id="Path-28" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M420.273,78.871l-14.945,12.375"/>
                        </g>
                        <g id="man_8">
                            <path id="Path-21" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M378.051,79.609l-0.176,16.297"/>
                            <path id="Path-22" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M371.559,88.5c5.111-1.307,10.225-2.615,15.336-3.922"/>
                            <path id="Path-23" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M374.652,80.57c3.311,4.328,6.623,8.656,9.934,12.984"/>
                            <path id="Path-24" fill="none" stroke="#000000" strokeWidth="3"
                                  d="M385.805,76.387c-10.119,13.075-6.959,6.946-11.285,17.34"/>
                        </g>
                        <path id="man_9" name="head" fill="none" stroke="#000000" strokeWidth="6" d="M395.25,57.435c-6.445,0-12.965-0.978-19.336,0
		c-0.979,0.15,0.85,2.461,0,2.969c-1.419,0.849-3.541-0.847-4.961,0c-0.848,0.506,0.617,2.19,0,2.961
		c-48.128,60.112,13.084-17.559-8.891,4.445c-9.344,9.355-23.9,29.05-13.336,45.938c27.277,43.604-4.16-7.146,8.891,5.922
		c1.561,1.563,1.123,4.704,2.961,5.93c19.691,13.13,35.845,12.872,59.273,11.852c5.634-0.245,1.359-1.883,2.961-4.445
		c2.044-3.271,5.577-5.495,7.406-8.891c7.04-13.067,11.072-42.271,1.484-51.859c-10.773-10.773-36.744-13.336-52.008-13.336"/>

                        <g id="stand">
                            <rect id="man_10" x="386.039" width="8" height="60"/>
                            <rect id="man_11" x="185.039" width="208" height="8"/>
                            <rect id="man_12" x="185.039" width="8" height="409"/>
                            <rect id="man_13" x="116.039" y="399" width="146" height="22"/>
                        </g>
                    </svg>
                    <ul className={className} id="hangman_game_letters">
                        {letters}
                    </ul>
                </div>

            )
    }
});